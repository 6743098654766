@charset "utf-8";

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $color-black;
    background: $color-white;
    margin: auto;
    margin-bottom: 50px;
    width: 500px;
}
p {
    word-break: break-word;
}
a {
    color: $color-black;
    background: linear-gradient(to bottom, transparent 0%, transparent 60%, $color-aqua 60%, $color-aqua 100%);
    text-decoration: none;
}
a:hover {
    background: linear-gradient(to bottom, transparent 0%, transparent 10%, $color-aqua 10%, $color-aqua 100%);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 42px;
    word-break: break-word;
}
blockquote {
    font-style: italic;
    margin-top: 40px;
    padding: 0 1rem 0 1rem;
    border-left: 5px solid $border-color;
    border-right: 5px solid $border-color;
}
img {
    max-width: 100%;
}
table {
    border-collapse: separate;
    border-spacing: 0;
    max-width: 100%;
    width: 100%;
}
th,
td {
    padding: 0.5rem;
    line-height: inherit;
}
th {
    text-align: left;
    vertical-align: bottom;
    border-bottom: 2px solid $border-color;
}
td {
    vertical-align: top;
    border-bottom: 1px solid $border-color;
}
pre {
    padding: 1.25rem;
    overflow-x: auto;
}
code {
    font-family: "DejaVu Sans Mono",courier,monospace;
    font-size: $small-font-size;
    tab-size: 4;
}
hr {
    border: none;
    border-top: 2px solid $border-color;
}
abbr {
    background: linear-gradient(to bottom, transparent 0%, transparent 10%, $color-yellow 10%, $color-yellow 100%);
    text-decoration: none;
}
