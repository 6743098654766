@charset "utf-8";
main {
    text-align: left;
}
.posts {
    list-style: none;
    padding: 0;
    > li {
        line-height: 1.75;
        span {
            color: $meta-color;
            margin-right: 1.5rem;
            font-size: $small-font-size;
        }
    }
}
.post {
    position: relative;
    .post-header {
        margin-bottom: 0;
    }
    .post-meta {
        margin: 0;
        color: $meta-color;
        font-size: $small-font-size;
    }
}
