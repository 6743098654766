@charset "utf-8";

@font-face {
  font-family: 'Latin Modern';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../files/LM-regular.woff2') format('woff2'),
}

@font-face {
  font-family: 'Latin Modern';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../files/LM-italic.woff2') format('woff2'),
}

@font-face {
  font-family: 'Latin Modern';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../files/LM-bold.woff2') format('woff2'),
}

@font-face {
  font-family: 'Latin Modern';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../files/LM-bold-italic.woff2') format('woff2'),
}

$base-font-family: "Latin Modern", serif;
$base-font-size: 1.1em;
$small-font-size: 0.875rem;
$base-line-height: 1.45;

#portrait {
    max-width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

$meta-color: #a0a0a0;
$border-color: #ddd;

@import "color";
@import "base";
@import "layout";
@import "syntax";

@media (prefers-color-scheme: dark) {
    body {
        color: $color-white;
        background: $color-black;
    }
    a {
        color: $color-white;
    }
    abbr {
        color: $color-black;
    }
}

@media (prefers-color-scheme: light) {
    body {
        color: $color-black;
        background: $color-white;
    }
    a {
        color: $color-black;
    }
    abbr {
        color: $color-black;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    body {
        font-size: 1em;
        padding: 0px 30px;
        width: auto;
    }
    h1, h2, h3, h4, h5, h6 {
        line-height: 40px;
    }
    abbr {
        border: none;
    }
    #kok {
        display: none;
    }
}
